import React from 'react';
import PageNotFoundOrUserNotAuthorized from '../components/404';
import HelmLayout from '../components/helm/helmLayout';

function NotFound() {
    return (
        <HelmLayout>
            <PageNotFoundOrUserNotAuthorized />
        </HelmLayout>
    );
}

export default NotFound;
